import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@/components/organisms/Layout';
import ProductContainer from '@/containers/Product/ProductContainer';
import Seo from '@/components/atoms/Seo/Seo';

import { ProductTemplateQuery } from '../../graphql-types';

interface Props {
  data: ProductTemplateQuery;
}

export const query = graphql`
  query ProductTemplate($id: String!) {
    prismicProduct(id: { eq: $id }) {
      data {
        ...ProductHeader
        ...ProductFeatures
      }
    }
    prismicCompanyStats {
      data {
        ...CompanyStats
      }
    }
    prismicHomePageBodyContactbox {
      primary {
        image {
          url
          alt
        }
      }
    }
  }
`;

const ProductTemplate = ({ data }: Props): JSX.Element => {
  return (
    <Layout invertNav type="magasin">
      <Seo title="Product" description="Product" />
      <ProductContainer data={data} />
    </Layout>
  );
};

export default ProductTemplate;
