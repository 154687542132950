import React from 'react';
import styled from '@emotion/styled';

import Container from '@/components/atoms/Container';
import ContactBox from '@/components/molecules/ContactBox';

import mq from '@/styles/mq';

import { ContactBoxProps } from '@/components/molecules/ContactBox/ContactBox';

const Root = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing(10)};
  margin-bottom: ${({ theme }) => theme.spacing(5)};

  ${mq('lg')} {
    margin-top: ${({ theme }) => theme.spacing(15)};
    margin-bottom: ${({ theme }) => theme.spacing(15)};
  }
`;

const ProductContactBox = (props: ContactBoxProps): JSX.Element => {
  return (
    <Root maxWidth={1040}>
      <ContactBox {...props} />
    </Root>
  );
};

export default ProductContactBox;
