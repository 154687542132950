import React from 'react';
import styled from '@emotion/styled';

import Container from '@/components/atoms/Container';
import CompanyStats from '@/components/molecules/CompanyStats';

import mq from '@/styles/mq';

import { CompanyStatsProps } from '@/components/molecules/CompanyStats/CompanyStats';

const Root = styled(Container)`
  display: none;

  ${mq('lg')} {
    display: flex;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.spacing(10)};
    margin-bottom: ${({ theme }) => theme.spacing(10)};
  }
`;

const ProductStats = ({ stats }: CompanyStatsProps): JSX.Element => {
  return (
    <Root maxWidth="lg">
      <CompanyStats stats={stats} />
    </Root>
  );
};

export default ProductStats;
