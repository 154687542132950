import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import ProductHeader from '@/containers/Product/ProductHeader';
import ProductFeatures from '@/containers/Product/ProductFeatures';
import ProductStats from '@/containers/Product/ProductStats';
import ProductContactBox from '@/containers/Product/ProductContactBox';

import {
  PrismicCompanyStatsStatsGroupType,
  ProductTemplateQuery,
} from '../../../graphql-types';

interface Props {
  data: ProductTemplateQuery;
}

const ProductContainer = ({ data }: Props): JSX.Element => {
  const {
    application_features,
    area_of_use,
    chemical,
    description,
    image,
    main_features,
    name,
    physical,
    security,
    storage,
    technical_sheet,
    composition,
    liter,
    external_site_products,
  } = data?.prismicProduct?.data || {};

  // const {
  //   contact_box_background,
  //   contact_box_section_title,
  //   contact_box_content,
  // } = data?.prismicContactBox?.data || {};

  // const { stats } = data?.prismicCompanyStats?.data || {};

  const { primary } = data?.prismicHomePageBodyContactbox || {};

  const ProductHeaderProps = {
    name: <RichText render={name?.raw} />,
    mainFeatures: <RichText render={main_features?.raw} />,
    image: {
      url: image?.url,
      alt: image?.alt,
    },
    description: <RichText render={description?.raw} />,
    areaOfUse: <RichText render={area_of_use?.raw} />,
    technical: technical_sheet?.url,
    composition,
    liter,
    external_site_products,
  };

  // const ProductFeaturesProps = {
  //   chemical: <RichText render={chemical?.raw} />,
  //   physical: <RichText render={physical?.raw} />,
  //   storage: <RichText render={storage?.raw} />,
  //   security: <RichText render={security?.raw} />,
  //   applicationFeatures: <RichText render={application_features?.raw} />,
  // };

  const ContactBoxProps = {
    background: {
      url: primary?.image?.url,
      alt: primary?.image?.alt,
    },
    title: 'Vous souhaitez plus d’informations ?',
    content:
      'Nos équipes en magasin sont à votre disponibilité pour tout type de questions. Vous pouvez également remplir notre formulaire de contact.',
    button: {
      text: 'Nous contacter',
      link: '/magasin/contact',
    },
  };

  const Stats = [
    {
      number: '80+',
      label: "années d'expertise",
    },
    {
      number: '0',
      label: 'intermédiaire',
    },
    {
      number: '0',
      label: 'déchet pour objectif',
    },
    {
      number: '30k+',
      label: 'tons disponibles',
    },
  ];
  const StatsProps = {
    stats: Stats.map((el) => ({
      number: el?.number,
      label: el?.label,
    })),
  };

  return (
    <>
      <ProductHeader {...ProductHeaderProps} />
      {/* <ProductFeatures {...ProductFeaturesProps} /> */}
      <ProductContactBox {...ContactBoxProps} />
      <ProductStats {...StatsProps} />
    </>
  );
};

export default ProductContainer;
